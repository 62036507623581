import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ChatManagement from "../components/ManageTenants/ChatManagement";
import _ from "lodash";
import { Badge, CircularProgress, IconButton, Tooltip } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import io from "socket.io-client";

import ListingInfo1 from "../components/ManageTenantsDet/ListingInfo1";
import ProfileSpinner from "../components/profile/ProfileSpinner";
import {
  getTenantById,
  getTenantDetailsById,
  updateTenantge_image,
} from "../Services/tenantService";
import UnreadMsgsContext from "../unreadmessages/context";
import { getLang } from "../Utils/getLang";
import {
  clientGetAvailableSpaces,
  getListingsById,
} from "../Services/listings";
import Meta from "../Utils/Meta";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import SearchPaymentTenant1 from "../components/ManageTenantsDet/SearchPaymentTenant1";
import TableTenantPaymentNonmen from "../components/ManageTenantsDet/TableTenantPaymentNonmen";
import { myDateToSort } from "../Utils/currentDate";
import logger from "../Services/loggerService";
import NonMemberPayModal from "../components/ManageTenantsDet/NonMemberPayModal";
import {
  getPaymentByListing_id,
  getPaymentByPayer_id,
  payNowNonMember,
  postAndDownloadPaymentNonmemberService,
} from "../Services/payment";
import { trackEvent } from "../Utils/useGAEventTracker";
import ModalProfile from "../components/profile/ModalProfile";
import { getCurrentUser } from "../Services/authService";
import { uploadSingleFileToCloudinary } from "../Utils/imageUploadToCloudinary";
import { getCloudinarySignature } from "../Services/cloudinaryService";
import DownShowSideBar from "../components/AddListing/DownShowSideBar";
import OpenButton from "../components/AddListing/OpenButton";
import Sidebar from "../components/AddListing/Sidebar";
import CloseDeal from "../components/LeaseSignDetails/CloseDeal";

const ManageTenantDetailNonMem = ({ match }) => {
  const [ID, setID] = useState("");
  const [tenant, setTenant] = useState({});
  const [close, setClose] = useState(true);
  const [loadMyData, setLoadMyData] = useState(false);
  const [path, setPath] = useState("");
  const [listing, setListing] = useState({});
  const [payments, setPayments] = useState([]);
  // const [loadingSearchPayment, setLoadingSwearchPayment] = useState(false)
  const [paymentDetails, setPaymentDetails] = useState({});
  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });

  const [openPay, setOpenPay] = useState(false);
  const [amount, setAmount] = useState();
  const [error, setError] = useState("");
  const [upSideBar, setUpSideBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedPaymentTable, setSelectedPaymentTable] = useState([]);
  const [selectedPaymentToLandlord, setSelectedPaymentToLandlord] = useState(
    {}
  );
  const [loadingSearchPayment, setLoadingSearchPayment] = useState(false);
  const [picturePath, setPicturePath] = useState("");
  const [loadingImage, setLoadingImage] = useState(false);
  const [errorPicture, setErrorPicture] = useState("");
  const [uploadPicLoading, setUploadPicLoading] = useState(false);
  const [myImageData, setMyImageData] = useState({});
  const [fileNames, setFileNames] = useState("");

  const [availableSpace, setAvailableSpace] = useState([]);
  const [tenantSpace, setTenantSpace] = useState({});
  const [loadDownloadAgreement, setLoadDownloadAgreement] = useState(false);

  const [errorCloseLease, setErrorCloseLease] = useState("");
  const [loadingCloseLease, setLoadingCloseLease] = useState(false);

  const [listingAnalysis, setListingAnalysis] = useState({
    payment_det: [],
    payments: [],
  });

  const [socket, setSocket] = useState();

  const { setTenants: setTening, unreadMsgs } = useContext(UnreadMsgsContext);

  const [quantity, setQuantity] = useState(1);

  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("portalPath", "/portal/tenants");
    const myPath = localStorage.getItem("portalPath");
    setPath(myPath);
    getTenantData();
  }, []);

  const getTenantData = async () => {
    try {
      setLoading(true);
      setID(match.params.id);
      const { data: tenant } = await getTenantDetailsById(match.params.id);
      setTenant(tenant);
      const { data: listing } = await getListingsById(tenant.listing_id);
      setListing(listing);

      const { data: tenanting } = await getPaymentByPayer_id(tenant.tenant_id);
      const sortedPayments = _.orderBy(
        tenanting.payment,
        [sortColumn.path],
        [sortColumn.order]
      );
      const sortedPaymentss = _.orderBy(
        tenanting.payment_det,
        [sortColumn.path],
        [sortColumn.order]
      );

      setPayments(sortedPayments);
      setPaymentDetails(sortedPaymentss);

      const { data: imagesData } = await getCloudinarySignature();
      setMyImageData(imagesData);

      const { data: listingPayment } = await getPaymentByListing_id(listing.id);
      setListingAnalysis(listingPayment);

      const { data: spaces } = await clientGetAvailableSpaces(listing.id);
      setAvailableSpace(spaces);

      for (let i = 0; i < spaces.length; i++) {
        if (
          spaces[i].all_occupants.some(
            (occ) => occ.occupant_id === tenant.tenant_id
          )
        ) {
          setTenantSpace(spaces[i]);
          break;
        } else {
        }
      }

      const socketing = io();
      setSocket(socketing);

      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  };

  //function to close the sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to upon the sidebar
  const onClickOpenBar = () => {
    setClose(true);
  };
  function commaSeparateNumber(val) {
    const myVal = Array.from(`${val}`);

    if (myVal.includes(",")) {
      return val;
    } else {
      while (/(\d+)(\d{3})/.test(val.toString())) {
        val = val.toString().replace(/(\d+)(\d{3})/, "$1" + "," + "$2");
      }
      return val;
    }
  }

  //function to get the date the leases starts the date it ends
  const dateLeaseStartAndExpiry = () => {
    const datee = tenant.agreement_start
      ? new Date(tenant.agreement_start && tenant.agreement_start.slice(0, 10))
      : new Date();
    const yearLeaseStart = datee.getFullYear();
    const monthLeaseStart = datee.getMonth();
    const dayLeaseStart = tenant.agreement_start
      ? tenant.agreement_start.slice(8, 10)
      : "";

    const endDatee = tenant.agreement_end ? new Date(tenant.agreement_end) : "";

    const yearLeaseEnd = endDatee ? endDatee.getFullYear() : "";
    const monthLeaseEnd = endDatee ? endDatee.getMonth() : "";
    const dayLeaseEnd = endDatee ? endDatee.getDay() : "";

    return {
      startDate: `${dayLeaseStart}/${monthLeaseStart + 1}/${yearLeaseStart}`,
      endDate: endDatee
        ? `${dayLeaseEnd}/${monthLeaseEnd + 1}/${yearLeaseEnd}`
        : "",
    };
  };

  //function to calculate the duration which the tenant have stayed in the house
  const durationStayed = () => {
    const datee = tenant.agreement_start
      ? new Date(tenant.agreement_start.slice(0, 10))
      : new Date();
    const today = new Date();
    const yearLeaseStart = datee.getFullYear();
    const monthLeaseStart = datee.getMonth();
    const dayLeaseStart = tenant.agreement_start
      ? tenant.agreement_start.slice(8, 10)
      : "";
    const past = new Date(yearLeaseStart, monthLeaseStart, dayLeaseStart);

    const agreementCloseDate = tenant.lease_cancel_date
      ? new Date(tenant.lease_cancel_date)
      : "";
    let closeYear;
    let closeMonth;
    let closeDay;
    let closing;
    if (agreementCloseDate) {
      closeYear = agreementCloseDate.getFullYear();
      closeMonth = agreementCloseDate.getMonth();
      closeDay = tenant.lease_cancel_date
        ? tenant.lease_cancel_date.slice(8, 10)
        : "";
      closing = new Date(closeYear, closeMonth, closeDay);
    }

    const valueDuration = agreementCloseDate
      ? closing.getTime()
      : today.getTime();

    const diff = Math.floor(valueDuration - past.getTime());

    const day = 1000 * 60 * 60 * 24;

    var days = Math.floor(diff / day);
    const months = Math.floor(diff / (day * 30));
    return months;
  };

  //function involve in searching payment
  const onChangeSearchAmount = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTableAmount(value, payments);
      setPayments(tableData);
    } else {
      try {
        setLoadingSearchPayment(true);
        const { data: tenanting } = await getPaymentByPayer_id(
          tenant.tenant_id
        );

        const sortedPayments = _.orderBy(
          tenanting.payment,
          [sortColumn.path],
          [sortColumn.order]
        );
        const sortedPaymentss = _.orderBy(
          tenanting.payment_det,
          [sortColumn.path],
          [sortColumn.order]
        );
        setPayments(sortedPayments);
        setPaymentDetails(sortedPaymentss);
        setLoadingSearchPayment(false);
      } catch (error) {
        logger.log(
          "error from onSearchListing function in ListingScreen component",
          error
        );
      }
    }
  };

  const searchTableAmount = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value?.toLowerCase();
      let title = data[i]?.amount?.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //filter payment by the date paid
  const onChangeSearchPayDate = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTableCreatedDate(value, payments);
      setPayments(tableData);
    } else {
      try {
        setLoadingSearchPayment(true);
        const { data: tenanting } = await getPaymentByPayer_id(
          tenant.tenant_id
        );

        const sortedPayments = _.orderBy(
          tenanting.payment,
          [sortColumn.path],
          [sortColumn.order]
        );
        const sortedPaymentss = _.orderBy(
          tenanting.payment_det,
          [sortColumn.path],
          [sortColumn.order]
        );
        setPayments(sortedPayments);
        setPaymentDetails(sortedPaymentss);
        setLoadingSearchPayment(false);
      } catch (error) {
        logger.log(
          "error from onSearchListing function in ListingScreen component",
          error
        );
      }
    }
  };

  const searchTableCreatedDate = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value?.toLowerCase();
      // const myValue= myDateToSort(value)
      let title = data[i]?.create_date?.toLowerCase();
      const myTitle = myDateToSort(title);
      if (myTitle.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //function to sort payment base on date amount and payment method
  const onChangeSort = (e) => {
    e.preventDefault();
    const paying = [...payments];
    const { value } = e.currentTarget;
    const column = { ...sortColumn };
    column.path = value;
    const sortedPayment = _.orderBy(paying, [column.path], [column.order]);
    setPayments(sortedPayment);
  };

  const onSubmitPay = async (e) => {
    e.preventDefault();
    if (!amount) return setError("Amount is not supposed to be empty");
    if (amount == 0) return setError("Amount is not supposed to be zero");
    try {
      setLoadMyData(true);
      const postData = {
        tenant,
        listing,
        amount,
      };
      const { data } = await payNowNonMember(postData);
      setLoadMyData(false);
      setOpenPay(false);
      const sortedPaymentss = _.orderBy(
        data.payment_details,
        [sortColumn.path],
        [sortColumn.order]
      );

      setPayments([data.payment, ...payments]);
      setPaymentDetails(sortedPaymentss);
      setTenant(data.tenant);

      setAmount(null);
      setError(null);

      ///////get all tenants owing money
      const { data: ten } = await getTenantById(getCurrentUser()?.id);
      setTening(ten.filter((t) => t.amount_owed > 0));
    } catch (ex) { }
  };

  //function that takes the side bar up
  const onTakeBarUp = () => {
    setUpSideBar(true);
  };

  //function to execute drop to show payment details
  const onClickPayment = (payment) => {
    trackEvent(
      "dropdown to show payment ",
      `${payment.id}`,
      "dropdown payment btn manage"
    );
    const myPays = [...selectedPaymentTable];
    if (myPays.includes(payment)) {
      const remainingPay = myPays.filter((pay) => pay !== payment);
      setSelectedPaymentTable(remainingPay);
    } else {
      myPays.push(payment);
      setSelectedPaymentTable(myPays);
    }
  };

  function capitalize(s) {
    return s?.toLowerCase()?.replace(/\b./g, function (a) {
      return a?.toUpperCase();
    });
  }

  //function to download the receipt
  const ondownloadReceipt = async (payment) => {
    try {
      trackEvent(
        "download payment receipt",
        `/createPDF/payment/${payment.id}`,
        "download payment receipt"
      );
      setSelectedPaymentToLandlord(payment);
      await postAndDownloadPaymentNonmemberService(payment, (data) =>
        setSelectedPaymentToLandlord(data)
      );
    } catch (error) {
      logger.log(error);
    }
  };

  //function to submit Image Path to the database
  const onSubmitPath = async (e) => {
    try {
      e.preventDefault();

      setLoadingImage(true);
      const { data: tenanting } = await updateTenantge_image({
        fileProfile: picturePath,
        tenant,
      });

      setTenant(tenanting);
      setLoadingImage(false);

      setErrorPicture("");
      TrackEvent(
        `${getCurrentUser() && getCurrentUser().id} update image`,
        picturePath,
        "upload profile image btn"
      );

      window.location = `/profile`;
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setErrorPicture(ex.response.data);
        setLoadingImage(false);
      }
    }
  };

  // function use to send file to cloudinary

  // function use to send file to cloudinary

  const handleFile = async (e) => {
    e.preventDefault();

    const size = e.currentTarget.files[0].size;
    const file = e.currentTarget.files[0];

    if (size > 8200683) {
      setErrorPicture(t("1.2Mb"));
    } else {
      setUploadPicLoading(true);

      let src = URL.createObjectURL(file);

      //convert to canvas
      const canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");

      let userImage = new Image();
      userImage.src = src;
      userImage.onload = () => {
        canvas.width = userImage.naturalWidth;
        canvas.height = userImage.naturalHeight;
        ctx.drawImage(userImage, 0, 0);

        //convert canvas to webP
        //     let webPImage = canvas.toDataURL('image/webp')

        canvas.toBlob(async (blob) => {
          const myImage = new File([blob], file.name, { type: blob.type });
          const result = await uploadSingleFileToCloudinary(
            myImage,
            myImageData
          );

          if (
            !result ||
            !result.url ||
            (result && result.path && result.path["error"])
          ) {
            setErrorPicture(
              "The was an error loading your image, please reload this image"
            );
            setUploadPicLoading(false);
            return;
          }
          setPicturePath(result);
          setUploadPicLoading(false);
        }, "image/webp");
      };
    }
  };

  const onClickCloseDeal = () => {
    if (
      window.confirm(
        "Are you sure you want to close this lease agreement ?. Closing this lease agreement will stop you from adding any payment to this lease agreement."
      )
    ) {
      const data = {
        landlord: getCurrentUser(),
        listing,
        tenant,
        tenant_space: tenantSpace,
      };
      setLoadingCloseLease(true);
      socket.emit("close_deal_non_members", data, (callback) => {
        setLoadingCloseLease(false);
        if (callback?.error) return setErrorCloseLease(callback?.error);
        setTenant(callback?.tenant);
      });
    }
  };

  document.getElementById("nonMembertenantOuterContainer") &&
    document
      .getElementById("nonMembertenantOuterContainer")
      .addEventListener("dblclick", () => setOpenPay(false));

  if (loading)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    );

  return (
    <>
      <Meta
        title={
          getLang() +
          t("meta_payment_payment details", {
            name: tenant.tenant_name,
          })
        }
      />
      <ModalProfile
        nomember={true}
        path={picturePath}
        onSubmitPicture={onSubmitPath}
        onChange={handleFile}
        errors={errorPicture}
        fileName={fileNames}
        loading={uploadPicLoading}
        loadingImage={loadingImage}
      />
      {openPay && (
        <NonMemberPayModal
          onClickCloseModal={() => setOpenPay(false)}
          onSubmitPay={onSubmitPay}
          onChange={(e) => setAmount(e.target.value)}
          error={error}
          value={amount}
          loadMyData={loadMyData}
          listing={listing}
          quantity={quantity}
          onChangeQuantity={(e) => {
            setQuantity(e.target.value);
            setAmount(Number(e.target.value) * Number(listing?.price));
          }}
        />
      )}

      {close && !upSideBar && (
        <Sidebar
          onClickCloseBar={onClickCloseBar}
          onTakeBarUp={onTakeBarUp}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {!close && (
        <OpenButton
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {upSideBar && (
        <DownShowSideBar onUponUpSideBar={() => setUpSideBar(false)} />
      )}

      <div
        id="nonMembertenantOuterContainer"
        className={
          close
            ? "tenantDetailsOuterContainers4"
            : "TheContainerThatTellsThatIsFlexible5"
        }
      >
        <div className="tenantDetailsTenantNameChatContainer">
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                flex: "1",
              }}
            >
              <div
                className="nonMemberProfileImageContainer1"
                data-toggle="modal"
                data-target="#exampleModalCenterProfile"
                style={{ position: "relative" }}
              >
                {tenant.picture ? (
                  <>
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      src={tenant.picture.url}
                    />
                    <FontAwesomeIcon
                      className="nonMemberImageIcon"
                      icon={faCamera}
                      style={{
                        zIndex: 4,
                        position: "absolute",
                        top: "0px",
                        right: "0px",
                      }}
                    />
                  </>
                ) : (
                  <a
                    type="button"
                    data-toggle="modal"
                    data-target="#exampleModalCenterProfile"
                  >
                    <FontAwesomeIcon
                      className="nonMemberImageIcon"
                      icon={faCamera}
                    />
                  </a>
                )}
              </div>
              {/* ////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////
      THE IS SIGN THAT WILL BE SHOWN AFTER THE LANDLORD AND THE TENANT HAVE CLOSED THE DEAL */}
              {tenant.tenant_close_deal && tenant.landlord_close_deal && (
                <div
                  style={{
                    position: "fixed",
                    marginTop: "100px",
                    zIndex: "100",
                    left: "17%",
                  }}
                >
                  <CloseDeal />
                </div>
              )}
              <div
                style={{
                  font: "normal normal bold 18px/25px Merriweather",
                  marginLeft: "10px",
                }}
              >
                {capitalize(`${tenant.tenant_name}`)}
              </div>
              {loadDownloadAgreement ? (
                <CircularProgress size={20} />
              ) : (
                <Tooltip
                  title={`${capitalize(`${tenant.tenant_name}`)} is occupying ${listing?.listing_type
                    } Number ${tenantSpace?.space_number} of ${capitalize(
                      `${listing?.title}`
                    )}, download the lease agreement`}
                  placement="top"
                >
                  <a
                    href={`https://res.cloudinary.com/captivator/image/upload/fl_attachment/${tenant?.sign_lease_img?.filePath?.public_id}.${tenant?.sign_lease_img?.filePath?.format}`}
                    download
                  >
                    <IconButton type="button">
                      <Badge
                        style={{ margin: "7px" }}
                        badgeContent={tenantSpace?.space_number}
                        color="success"
                      >
                        <DownloadIcon
                          style={{ cursor: "pointer", color: "#000000" }}
                          color="action"
                        />
                      </Badge>
                    </IconButton>
                  </a>
                </Tooltip>
              )}
            </div>
          </div>

          <div>
            <ChatManagement
              chats={
                unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
              }
            />
          </div>
        </div>
        <div className="theTenantDetailsForPaymentByTenant">
          <div className="alert alert-info">
            {t("PAYMENTS_FOR_user", {
              listing:
                tenant &&
                tenant.building_name &&
                tenant.building_name.toUpperCase(),
            })}
          </div>
          <SearchPaymentTenant1
            onChangeSearchAmount={onChangeSearchAmount}
            onChangeSearchPayDate={onChangeSearchPayDate}
            onChangeSort={onChangeSort}
            currency={listing && listing.currency}
            onClickAddPayment={() => setOpenPay(true)}
            tenant={tenant}
          />
          {payments.length > 0 ? (
            <TableTenantPaymentNonmen
              payments={payments}
              paymentDetails={paymentDetails}
              listingCurrency={listing.currency}
              loadingSearchPayment={loadingSearchPayment}
              ondownloadReceipt={ondownloadReceipt}
              selectedPaymentToLandlord={selectedPaymentToLandlord}
              onClickPayment={onClickPayment}
              selectedPaymentTable={selectedPaymentTable}
            />
          ) : loadingSearchPayment ? (
            <div style={{ width: "100%", textAlign: "center" }}>
              {t(".....loading")}
            </div>
          ) : (
            <div className="noListingAvailableYetCon ">
              <div className="alert">{t("No_Payment_available")}</div>
            </div>
          )}
        </div>
        <div style={{ marginTop: "50px" }}>
          {listing && (
            <ListingInfo1
              listingAnalysis={listingAnalysis}
              listing={listing}
              tenant={tenant}
              // landlordDetails={myPersonalData}
              dateLeaseStartAndExpiry={dateLeaseStartAndExpiry}
              // detailLeaseSign={detailLeaseSign}
              // mySignLease={mySignLease}
              monthsCover={durationStayed()}
              tenantSpace={tenantSpace}
              availableSpace={availableSpace}
              onClickCloseDeal={onClickCloseDeal}
              errorCloseLease={errorCloseLease}
              loadingCloseLease={loadingCloseLease}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ManageTenantDetailNonMem;
