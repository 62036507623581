import React, { useEffect, useState, useContext } from "react";
import ScrollToTop from "react-scroll-to-top";
import _ from "lodash";
import cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import axios from "axios";

import HeaderSearch from "../components/Home/HeaderSearch";
import NumberOfListingsBar from "../components/Home/NumberOfListingsBar";
import DisplayCards from "../components/Home/DisplayCards";
import Footer1 from "../components/Home/Footer1";
import ProfileSpinner from "../components/profile/ProfileSpinner";
import { getClientAllAvailableSpaces, getListings } from "../Services/listings";
import { paginate } from "../Utils/pagination";
import { Countries } from "../Utils/regions";
import { Regions } from "../Utils/regions";
import { countries } from "../Utils/countries";
import selectedCountryInputs from "../Utils/translateInput";
import UnreadMsgsContext from "./../unreadmessages/context";
import "../components/Home/styleCompo.css";
import { getCurrentUser } from "../Services/authService";
import Meta1 from "../Utils/Meta1";
import PaginationMUI from "../components/Home/PaginationMUI";
import { getUsers } from "../Services/registerService";
import DisplayCardGrid from "../components/Home/DisplayCardGrid";
import formatArrayCount from "../Utils/convertArrayToKMB";

const HomeScreen = ({ history }) => {
  const [listings, setListings] = useState([]);
  const [loadingListings, setLoadingListings] = useState(false);
  const [error, setError] = useState("");

  const [pageSize, setPageSize] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);

  const [africaCountries, setAfricaCountries] = useState([]);
  const [selectedAfricaCountry, setSelectedAfricaCountry] = useState("");

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");

  const [currency, setCurrency] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");

  const [bedroom, setBedroom] = useState([]);
  const [selectedBedroom, setSelectedBedroom] = useState("");

  const [listing, setListing] = useState([]);
  const [selectedListing, setSelectedListing] = useState("");

  const [keyword, setKeyword] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);

  const [price, setPrice] = useState("");

  const [location, setLocation] = useState("");
  const [locationError, setLocationError] = useState({
    loaded: false,
    error: "",
  });
  const [userLocation, setUserLocation] = useState({
    country: "",
    city: "",
  });

  const [homeTitle, setHomeTitle] = useState("");

  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });

  const { unreadMsgs } = useContext(UnreadMsgsContext);

  const [langType, setLangType] = useState("");

  const [selectedItem, setSelectedItem] = useState(null);
  const [users, setUsers] = useState([]);

  const [availableSpaces, setAvailableSpaces] = useState([]);

  const [title, setTitle] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    try {
      localStorage.setItem("portalOrManagement", "");
      setHomeTitle(t("searching_any_props"));
      window.scrollTo(0, 0);
      getAllListings();
    } catch (ex) {
      if (ex.response && ex.response.status === 500) {
        setError("NO INTERNET");
      }
    }
  }, []);

  function decodeSecretMessage(data) {
    let grid = JSON.parse(data)

    let Xmax = Math.max(...grid.map(itemX => itemX.x))
    let Ymax = Math.max(...grid.map(itemY => itemY.y))

    let gridFinal = Array.from({ length: Ymax + 1 }, () => Array(Xmax + 1).fill(' '))

    grid.forEach((x, y, char) => {
      grid[y][x] = char
    })

    gridFinal.forEach(row => console.log(row.join('')))
  }


  //function to get all the datas for a given user
  const getAllListings = async () => {
    try {
      setLoadingListings(true);
      const { data } = await getListings();
      const { data: spaces } = await getClientAllAvailableSpaces();
      setAvailableSpaces(spaces);

      const publishData = data.filter((d) => d.publish === "publish");
      const allUndeletedListing = publishData.filter(
        (listing) => listing.status !== "deleted"
      );
      // const allUndeletedListing = publishData.filter(
      //   (listing) => listing.status !== "deleted" && listing.reviewed === true
      // );
      const sortedListing = _.orderBy(
        allUndeletedListing,
        [sortColumn.path],
        [sortColumn.order]
      );

      let sortedListing1 = [];

      for (let i = 0; i < sortedListing.length; i++) {
        let list = sortedListing[i];
        list.remainingSpace = spaces.filter(
          (space) => space.listing_id === list.id && space.empty_space == true
        ).length;
        sortedListing1.push(list);
      }

      setListings([
        ...sortedListing1.filter((listing) => listing.remainingSpace > 0),
        ...sortedListing1.filter((listing) => listing.remainingSpace === 0),
      ]);
      setLoadingListings(false);

      const { data: userss } = await getUsers();
      setUsers(userss);

      // fields involve to fill the select drop down in the search container
      let Africas = Countries();
      setAfricaCountries(Africas);

      const Bedrooms = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
        39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56,
        57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74,
        75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92,
        93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108,
        109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122,
        123, 124, 125, 126, 127, 128, 129, 130, 131, 132, 133, 134, 135, 136,
        137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149, 150,
        151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 162, 163, 164,
        165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178,
        179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192,
        193, 194, 195, 196, 197, 198, 199, 200,
      ];
      setBedroom(Bedrooms);

      const Listingss = [
        "Single family house",
        "Town house",
        "Apartment",
        "Studio",
        "Single Room",
        "Office",
        "Store/Shop",
        "Land",
        "Condos",
      ];
      setListing(Listingss);

      const Keywords = [
        "Pool",
        "Flowers",
        "Garden",
        "Playground",
        "Gym",
        "Balcony",
        "Air condition",
        "Furnished",
        "Inner Toilet",
        "Inner Kitchen",
        "Inner Bathroom",
        "Constant Water Supply",
        "Electricity",
        "Security",
        "Fence",
        "Internet",
        "Cable TV",
        "Parking",
        "Central Town",
        "Near School",
        "Near Hospital",
        "Near Market",
        "Near Police Station",
        "Near Bus Station",
        "Near Airport",
        "Near Beach",
        "Friendly Neighborhood",
        "Quiet Neighborhood",
        "Near Main Road",
      ];
      setKeyword(Keywords.sort());

      //code to get the user current location
      if (!("geolocation" in navigator)) {
        setLocation({
          ...location,
          loaded: true,
          error: {
            code: 0,
            message: "geolocation not supported",
          },
        });
      } else {
        navigator.geolocation.getCurrentPosition(onSuccess, onError, {
          enableHighAccuracy: true,
        });
      }

      setLangType(cookies.get("i18next") || "en");
    } catch (ex) {
      if (
        (ex.response && ex.response.status === 500) ||
        (ex.response && ex.response.status === 400)
      ) {
        setError("Opp!!!!, sorry check your network connection");
      }
    }
  };

  //function to get the latitude and longitude base on the users location
  const onSuccess = async (location) => {
    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      },
    });

    const { data: mapKey } = await axios.get("/map");

    //function to get towns and country base on the users location
    let url =
      mapKey &&
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location.coords.latitude},${location.coords.longitude}&key=${mapKey}`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        const myLocation = data.results[0].formatted_address;
        const locationArray = myLocation.split(",");
        const locationObj = {
          country: locationArray[locationArray.length - 1].trim(),
          city: selectedCountryInputs(
            locationArray[locationArray.length - 2].trim()
          ),
        };

        setUserLocation(locationObj);

        setSelectedAfricaCountry(
          locationArray[locationArray.length - 1].trim()
        );
        setSelectedCity(
          selectedCountryInputs(locationArray[locationArray.length - 2].trim())
        );
      })
      .catch((err) =>
        console.warn("error from onSuccess function", err.message)
      );

    // const ctryTownResult = mapKey && currentTownAndCountry(location, mapKey)

    // setUserLocation(ctryTownResult && ctryTownResult.locationObj)

    // setSelectedAfricaCountry(
    //   ctryTownResult.locationArray[
    //     ctryTownResult.locationArray.length - 1
    //   ].trim()
    // )
    // setSelectedCity(
    //   selectedCountryInputs(
    //     ctryTownResult.locationArray[
    //       ctryTownResult.locationArray.length - 2
    //     ].trim()
    //   )
    // )
  };

  const onError = (error) => {
    setLocationError({
      loaded: true,
      error,
    });
    setLocation({
      loaded: false,
      coordinates: {
        lat: "",
        lng: "",
      },
    });
  };

  //function use for filtering and paginating listings
  const getPagedData = () => {
    let filtered = [...listings];

    if (selectedBedroom) {
      filtered = filtered.filter(
        (filter) => filter.bedrooms === selectedBedroom
      );
    }

    if (selectedListing) {
      filtered = filtered.filter(
        (filter) => filter.listing_type === selectedListing
      );
    }

    if (price) {
      filtered = filtered.filter(
        (filter) => Number(filter.price) >= Number(price.trim())
      );
    }

    if (selectedKeyword.length > 0) {
      filtered = filtered.filter((filter) =>
        filter.keyword.some((r) => selectedKeyword.includes(r))
      );
    }

    if (selectedCity) {
      filtered = filtered.filter(
        (filter) => filter.city.trim() === selectedCity.trim()
      );
    }

    if (selectedState) {
      filtered = filtered.filter((filter) => filter.state === selectedState);
    }

    if (selectedAfricaCountry) {
      filtered = filtered.filter(
        (filter) => filter.country === selectedAfricaCountry
      );
    }

    if (title) {
      filtered = filtered.filter((filter) =>
        filter.title.toLowerCase().includes(title.toLowerCase())
      );
    }

    const items = paginate(filtered, currentPage, pageSize);

    return { totalCount: filtered.length, data: items };
  };

  //function that is call when a page is click
  const handlePageChange = (e, page) => {
    setCurrentPage(page);
  };

  //function to go to next page when presses the next button
  const nextPage = () => {
    let currentPages = currentPage;
    currentPages = currentPages + 1;
    setCurrentPage(currentPages);
  };

  //function to go to previous page when press the previous button
  const previousPage = () => {
    let count = currentPage;
    count = currentPage - 1;
    count = count <= 1 ? 1 : count;
    setCurrentPage(count);
  };

  const onHoverListing = (listing) => {
    setSelectedItem(listing);
  };

  const { totalCount, data: items } = getPagedData();

  if (loadingListings)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    );
  if (error)
    return (
      <div className="homeSpinnerContainer">
        <div className="alert alert-danger">{error}</div>
      </div>
    );
  return (
    <div className="homeOuterContainer">
      <Meta1
        title={`${langType}.Nawafrica | Home`}
        description="Rent, Buy And Sell Properties in Africa"
        keywords="Rent, Buy, sel Properties Africa"
      />

      <HeaderSearch
        title={title}
        availableSpaces={availableSpaces}
        onClickMessage={() =>
          getCurrentUser().post_rent_property === "I AM A PROPERTY OWNER"
            ? history.push("/portal/messages/message")
            : history.push("/portal/tenants/message")
        }
        numberOfUnreadMsgs={
          unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
        }
        homeTitle={homeTitle}
        latestItem={items.slice(0, 10)}
        itemsCountry={countries()}
        rentOrSell=""
        onChangeCountry={(e, values) => {
          let state = Regions();

          let value = values ? values.label.trim() : "";

          if (value === "All Countries" || !value) {
            setSelectedCity("");

            setSelectedCurrency("");

            setSelectedState("");

            setSelectedListing("");

            setSelectedBedroom("");

            setPrice("");

            setSelectedKeyword([]);

            setSelectedAfricaCountry("");

            setCurrentPage(1);

            return;
          }

          if (
            selectedCity ||
            selectedCurrency ||
            selectedState ||
            selectedListing ||
            selectedBedroom ||
            price ||
            (selectedKeyword && !selectedAfricaCountry)
          ) {
            setSelectedCity("");

            setSelectedCurrency("");

            setSelectedState("");

            setSelectedListing("");

            setSelectedBedroom("");

            setPrice("");

            setSelectedKeyword([]);

            setCurrentPage(1);
          }

          setSelectedAfricaCountry(value);

          const statess =
            value !== ""
              ? state.filter((s) => s.country === value)[0].states
              : [];

          const Citiess =
            value !== ""
              ? state.filter((s) => s.country === value)[0].cities
              : [];

          let Currencys =
            value !== ""
              ? state.filter((s) => s.country === value)[0].currency
              : [];

          setStates(statess);

          setCities(Citiess);

          setCurrency(Currencys);
        }}
        itemsState={states}
        onChangeState={(e, values) => {
          let value = values ? values.trim() : "";
          if (value) {
            setCurrentPage(1);
            setSelectedState(value);
          } else {
            setCurrentPage(1);
            setSelectedState("");
          }
        }}
        itemsCity={cities}
        onChangeCity={(e, values) => {
          let value = values ? values.trim() : "";
          if (value) {
            setCurrentPage(1);
            setSelectedCity(value);
          } else {
            setCurrentPage(1);
            setSelectedCity("");
          }
        }}
        itemsBedroom={bedroom}
        onChangeBedroom={(e, values) => {
          let value = values ? Number(values) : "";
          if (value > 0) {
            setCurrentPage(1);
            setSelectedBedroom(`${value}`);
          } else {
            setCurrentPage(1);
            setSelectedBedroom("");
          }
        }}
        itemsListingType={listing}
        onChangeListingType={(e, values) => {
          let value = values ? values.trim() : "";
          if (value) {
            setCurrentPage(1);
            setSelectedListing(value);
          } else {
            setCurrentPage(1);
            setSelectedListing("");
          }
        }}
        itemsCurrency={currency}
        onChangeCurrency={(e) => {
          let value = e.currentTarget.value.trim();
          if (value) setCurrentPage(1);
          setSelectedCurrency(value);
        }}
        itemsKeyword={keyword}
        onChangeKeyword={(e, values) => {
          const {
            target: { value },
          } = e;
          setCurrentPage(1);
          setSelectedKeyword(
            typeof value === "string" ? value.split(",") : value
          );
        }}
        onChangePrice={(e) => {
          let value = e.currentTarget.value.trim();
          if (value) setCurrentPage(1);
          setPrice(value);
        }}
        onChangeListingTitle={(e) => {
          let value = e.currentTarget.value.trim();
          if (value) setCurrentPage(1);
          setTitle(value);
        }}
        valueCountry={selectedAfricaCountry}
        valueState={selectedState}
        valueCity={selectedCity}
        valueBedroom={selectedBedroom}
        valueListingType={selectedListing}
        valueKeyword={selectedKeyword}
        valuePrice={price}
        valueTitle={title}
        selectedAfricaCountry={selectedAfricaCountry}
        selectedState={selectedState}
        selectedCity={selectedCity}
        selectedBedroom={selectedBedroom}
        selectedListing={selectedListing}
        selectedKeyword={selectedKeyword}
        price={price}
      />

      <NumberOfListingsBar
        children={t("numberOfList_displayBar", {
          totalCount: totalCount === 0 ? t("No") : formatArrayCount(items),
          selectedListing: selectedListing ? t(selectedListing) : t("Listing"),
          selectedAfricaCountry: selectedAfricaCountry
            ? `${selectedCity && selectedCountryInputs(selectedCity) + ", "
            }${selectedCountryInputs(selectedAfricaCountry)}`
            : userLocation.city && selectedAfricaCountry
              ? `${userLocation.city}, ${userLocation.country}`
              : t("Nawafrica_Marketplace"),
        })}
      />
      <DisplayCardGrid
        selectedItem={selectedItem}
        items={items}
        onHoverListing={onHoverListing}
        users={users}
        availableSpaces={availableSpaces}
      />

      <PaginationMUI
        count={Math.ceil(totalCount / pageSize)}
        onChange={handlePageChange}
      />
      <div className="homeHrContainer">
        <hr />
      </div>
      <Footer1 />
      <ScrollToTop smooth />
    </div>
  );
};

export default HomeScreen;
