import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import io from "socket.io-client";
import logger from "../Services/loggerService";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";

// import SideBarManagement from '../components/ManageTenants/SideBarManagement'
// import OpenButtonManagement from '../components/ManageTenants/OpenButtonManagement'
import ChatManagement from "../components/ManageTenants/ChatManagement";
import TitleManageTenant from "../components/ManageTenants/TitleManageTenant";
import SearchTenants from "../components/ManageTenants/SearchTenants";
import TenantTable from "../components/ManageTenants/TenantTable";
import AddTenantModal from "../components/ManageTenants/AddTenantModal";
import ProfileSpinner from "../components/profile/ProfileSpinner";

import {
  editTenant,
  getTenantById,
  postTenant
} from "../Services/tenantService";
import { getCurrentUser } from "./../Services/authService";
import { getCloudinarySignature } from "../Services/cloudinaryService";
import { uploadMultipleFileToCloudinary, uploadSingleFileToCloudinary } from "./../Utils/imageUploadToCloudinary";
import { getClientAllAvailableSpaces, getListings } from "../Services/listings";

import "../components/ManageTenants/manageTenants.css";
import UnreadMsgsContext from "./../unreadmessages/context";
import DownShowSideBar from "../components/AddListing/DownShowSideBar";
import { getLang } from "../Utils/getLang";
import { trackEvent } from "../Utils/useGAEventTracker";
import { postAndDownloadAgreementService } from "../Services/applicationStatus";
import OpenButton from "../components/AddListing/OpenButton";
import Sidebar from "../components/AddListing/Sidebar";
import Meta1 from "../Utils/Meta1";
import { registeredUsers } from "../Services/registerService";

const ManageTenants = () => {
  const { t } = useTranslation();

  const [path, setPath] = useState("");
  const [close, setClose] = useState(true);
  const [upSideBar, setUpSideBar] = useState(false);

  const [tenants, setTenants] = useState([]);
  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });
  const [loading, setLoading] = useState(false);

  const [fromSearch, setFromSearch] = useState(false);
  const [loadingSearchListings, setLoadingSearchListings] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [aptNumber, setAptNumber] = useState("");
  const [passportOrId, setPassportOrId] = useState("");
  const [idCardNum, setIdCardNum] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [houseAddress, setHouseAddress] = useState("");

  const [uploadIdCard, setUploadIdCard] = useState("");
  const [photoNameIdCard, setPhotoNameIdCard] = useState("");
  const [errorUploadIdCard, setErrorUploadIdCard] = useState("");
  const [loadUploadIdCard, setLoadUploadIdCard] = useState(false);

  const [uploadLeaseSign, setUploadLeaseSign] = useState([]);
  const [errorUploadLeaseSign, setErrorUploadLeaseSign] = useState("");
  const [loadLeaseSign, setLoadLeaseSign] = useState(false);
  const [photoNameSignedLease, setPhotoNameSignedLease] = useState("");

  const [errorInInput, setErrorInInput] = useState("");
  const [postingTenant, setPostingTenant] = useState(false);
  const [myTenants, setMyTenants] = useState([]);

  const [socket, setSocket] = useState();

  const [tenantToDownloadPDF, setTenantToDownloadPDF] = useState("");
  const [myImageData, setMyImageData] = useState({});

  const [listings, setListings] = useState([]);
  const [myListings, setMyListings] = useState([]);
  const [myListings1, setMyListings1] = useState([]);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState("");
  const [users, setUsers] = useState([]);

  const [selectedListingSpaces, setSelectedListingSpaces] = useState([]);
  const [availableSpaces, setAvailableSpaces] = useState([]);
  const [availableSpaces1, setAvailableSpaces1] = useState([]);
  const [selectedListing, setSelectedListing] = useState({});
  const [editing, setEditing] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState({});

  const { unreadMsgs } = useContext(UnreadMsgsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("portalPath", "/portal/tenants");
    const myPath = localStorage.getItem("portalPath");
    setPath(myPath);
    const sock = io();
    setSocket(sock);
    getTenants();

    sock.on(`${getCurrentUser() && getCurrentUser().id}myTenants`, (data) => {
      setMyTenants(data);
    });

    return () => {
      localStorage.setItem("managementPath", "");
    };
  }, []);

  //function to get all Tenants for a given landlord
  const getTenants = async () => {
    try {
      if (getCurrentUser()) {
        setLoading(true);
        const { data } = await getTenantById(getCurrentUser().id);

        const sortedListing = _.orderBy(
          data,
          [sortColumn.path],
          [sortColumn.order]
        );

        const cancelled = sortedListing.filter(
          (s) => s.tenant_close_deal && s.landlord_close_deal
        );
        const uncancelled = sortedListing.filter(
          (s) => !s.tenant_close_deal && !s.landlord_close_deal
        );

        const tenantCancel = sortedListing.filter(
          (s) => s.tenant_close_deal && !s.landlord_close_deal
        );
        const landlordCancel = sortedListing.filter(
          (s) => !s.tenant_close_deal && s.landlord_close_deal
        );

        setMyTenants([
          ...uncancelled,
          ...tenantCancel,
          ...landlordCancel,
          ...cancelled,
        ]);

        const sock = io();
        sock.on(
          `${getCurrentUser() && getCurrentUser().id
          }tenant_cancelled_agreement`,
          (data) => {
            let tenant = data.tenant;
            let index = sortedListing.findIndex((t) => t.id == tenant.id);
            let remainingTenant = sortedListing.filter(
              (t) => t.id !== tenant.id
            );
            remainingTenant.splice(index, 0, tenant);
            setMyTenants(remainingTenant);
          }
        );

        const { data: imagesData } = await getCloudinarySignature();
        setMyImageData(imagesData);

        const { data: listings } = await getListings();
        setListings(listings);

        const myList = listings.filter(
          (l) =>
            l.list_owner_id === getCurrentUser().id && l.status !== "deleted"
        );
        setMyListings(myList);
        setMyListings1(myList);

        const { data: spaces } = await getClientAllAvailableSpaces();
        setAvailableSpaces(spaces);
        setAvailableSpaces1(spaces);

        const { data: uss } = await registeredUsers();
        setUsers(uss);

        setLoading(false);
      }
    } catch (error) {
      logger.log(
        "error from getTenants function in ManageTenants component",
        error
      );
    }
  };

  //function to close the sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to upon the sidebar
  const onClickOpenBar = () => {
    setClose(true);
  };

  //function to upload the signed lease document to the backend of the added tenant
  const onChangeUSL = async (e) => {
    e.preventDefault();
    setLoadLeaseSign(true);
    const filesss = e.currentTarget.files;
    let myFile = [];
    for (const file of filesss) {
      if (file.size > 24907530 && file.type.startsWith("video/")) {
        setErrorUploadLeaseSign(
          "large video file exist,Please upload a smaller video files not greater than 2050683gb"
        );
        setLoadLeaseSign(false);
      } else {
        const result = await uploadMultipleFileToCloudinary(file, myImageData);
        if (result) {
          myFile.push(result);
          setErrorUploadLeaseSign("");
        } else {
          setErrorUploadLeaseSign("Network error please retry");
          setLoadLeaseSign(false);
        }
      }
    }

    setUploadLeaseSign([...uploadLeaseSign, ...myFile]);
    setLoadLeaseSign(false);
  };

  //function to upload the ID card of the added tenant
  const onChangeUIC = async (e) => {
    e.preventDefault();
    const filesss = e.currentTarget.files[0];
    if (filesss.size > 8323863) {
      setErrorUploadIdCard(t("large_file,_upload"));
    } else {
      setLoadUploadIdCard(true);
      const result = await uploadSingleFileToCloudinary(filesss, myImageData);
      setUploadIdCard(result);
      setLoadUploadIdCard(false);
    }
  };

  //function to Submit the credentials of the added tenant to the backend
  const onSubmitTenants = async (e) => {
    try {
      e.preventDefault();
      const dataObj = {
        first_name: firstName,
        last_name: lastName,
        middle_name: middleName,
        listing_id: buildingName,
        appartment_num: aptNumber,
        id_type: passportOrId,
        id_number: idCardNum,
        job_title: jobTitle,
        contact_number: contactNumber,
        id_copy_img: uploadIdCard,
        photoNameIdCard,
        sign_lease_img: uploadLeaseSign,
        photoNameSignedLease,
        registered_tenant: false,
        startDate,
        endDate,
      };

      if (!firstName) return setErrorInInput(t("First Name is required"));
      if (!lastName) return setErrorInInput(t("Last Name is required"));
      if (!aptNumber) return setErrorInInput(t("Apartment Number is required"));
      if (!selectedListing?.listing_type)
        return setErrorInInput(t("Listing Type is required"));

      const space = selectedListingSpaces.find(
        (space) => Number(space.space_number) == Number(aptNumber) || space.space_name == aptNumber
      );

      if (!space) {
        return setErrorInInput(
          `Apartment Number ${aptNumber} is not available in the list of available spaces`
        );
      }

      setErrorInInput("");

      if (space && space.empty_space == false) {
        if (
          window.confirm(
            `Are you sure you want to add ${capitalize(
              `${firstName} ${lastName}`
            )} 
            into ${selectedListing?.listing_type
            } Number ${aptNumber} of ${capitalize(selectedListing?.title)} ?. 
            ${selectedListing?.listing_type
            } Number ${aptNumber} is already occupied by ${capitalize(
              space?.occupant_name
            )} and 
            will be replaced by ${capitalize(
              `${firstName} ${lastName}`
            )}. Please close lease agreement with ${capitalize(
              space?.occupant_name
            )} 
            before proceeding. Or call Captivator technologies to total close this agreement before proceeding`
          )
        ) {
          setPostingTenant(true);
          if (editing) {
            const { data } = await editTenant(dataObj, selectedTenant.id);
            const sortedListing = _.orderBy(
              data,
              [sortColumn.path],
              [sortColumn.order]
            );

            const cancelled = sortedListing.filter(
              (s) => s.tenant_close_deal && s.landlord_close_deal
            );
            const uncancelled = sortedListing.filter(
              (s) => !s.tenant_close_deal && !s.landlord_close_deal
            );

            const tenantCancel = sortedListing.filter(
              (s) => s.tenant_close_deal && !s.landlord_close_deal
            );
            const landlordCancel = sortedListing.filter(
              (s) => !s.tenant_close_deal && s.landlord_close_deal
            );

            setMyTenants([
              ...uncancelled,
              ...tenantCancel,
              ...landlordCancel,
              ...cancelled,
            ]);
          } else {
            const { data } = await postTenant(dataObj);
            const sortedListing = _.orderBy(
              data,
              [sortColumn.path],
              [sortColumn.order]
            );

            const cancelled = sortedListing.filter(
              (s) => s.tenant_close_deal && s.landlord_close_deal
            );
            const uncancelled = sortedListing.filter(
              (s) => !s.tenant_close_deal && !s.landlord_close_deal
            );

            const tenantCancel = sortedListing.filter(
              (s) => s.tenant_close_deal && !s.landlord_close_deal
            );
            const landlordCancel = sortedListing.filter(
              (s) => !s.tenant_close_deal && s.landlord_close_deal
            );

            setMyTenants([
              ...uncancelled,
              ...tenantCancel,
              ...landlordCancel,
              ...cancelled,
            ]);
          }
          trackEvent(
            "Add tenant by landlord",
            `${dataObj.first_name}`,
            "Add tenant btn"
          );

          setPostingTenant(false);
          setFirstName("");
          setMiddleName("");
          setLastName("");
          setBuildingName("");
          setAptNumber("");
          setPassportOrId("");
          setIdCardNum("");
          setJobTitle("");
          setContactNumber("");
          setUploadIdCard("");
          setPhotoNameIdCard("");
          setErrorUploadIdCard("");
          setLoadUploadIdCard("");
          setUploadLeaseSign("");
          setErrorUploadLeaseSign("");
          setPhotoNameSignedLease("");
          alert(t("Tenant_Posted"));
          setEditing(false);
          setSelectedTenant({});
        }
      } else {
        setPostingTenant(true);
        if (editing) {
          const { data } = await editTenant(dataObj, selectedTenant.id);
          const sortedListing = _.orderBy(
            data,
            [sortColumn.path],
            [sortColumn.order]
          );

          const cancelled = sortedListing.filter(
            (s) => s.tenant_close_deal && s.landlord_close_deal
          );
          const uncancelled = sortedListing.filter(
            (s) => !s.tenant_close_deal && !s.landlord_close_deal
          );

          const tenantCancel = sortedListing.filter(
            (s) => s.tenant_close_deal && !s.landlord_close_deal
          );
          const landlordCancel = sortedListing.filter(
            (s) => !s.tenant_close_deal && s.landlord_close_deal
          );

          setMyTenants([
            ...uncancelled,
            ...tenantCancel,
            ...landlordCancel,
            ...cancelled,
          ]);
        } else {
          const { data } = await postTenant(dataObj);
          const sortedListing = _.orderBy(
            data,
            [sortColumn.path],
            [sortColumn.order]
          );

          const cancelled = sortedListing.filter(
            (s) => s.tenant_close_deal && s.landlord_close_deal
          );
          const uncancelled = sortedListing.filter(
            (s) => !s.tenant_close_deal && !s.landlord_close_deal
          );

          const tenantCancel = sortedListing.filter(
            (s) => s.tenant_close_deal && !s.landlord_close_deal
          );
          const landlordCancel = sortedListing.filter(
            (s) => !s.tenant_close_deal && s.landlord_close_deal
          );

          setMyTenants([
            ...uncancelled,
            ...tenantCancel,
            ...landlordCancel,
            ...cancelled,
          ]);
        }
        trackEvent(
          "Add tenant by landlord",
          `${dataObj.first_name}`,
          "Add tenant btn"
        );
        setPostingTenant(false);
        setFirstName("");
        setMiddleName("");
        setLastName("");
        setBuildingName("");
        setAptNumber("");
        setPassportOrId("");
        setIdCardNum("");
        setJobTitle("");
        setContactNumber("");
        setUploadIdCard("");
        setPhotoNameIdCard("");
        setErrorUploadIdCard("");
        setLoadUploadIdCard("");
        setUploadLeaseSign("");
        setErrorUploadLeaseSign("");
        setPhotoNameSignedLease("");
        alert(t("Tenant_Posted"));
        setEditing(false);
        setSelectedTenant({});
      }
    } catch (ex) {
      setErrorInInput(ex.response.data);
      setPostingTenant(false);
    }
  };

  //function to delete a given tenant
  const onDelete = async (tenent) => {
    try {
      if (window.confirm(t("Are_you_sure_you_want_to_delete_this_Tenant"))) {
        const allTenants = [...myTenants];
        const remainingTenant = allTenants.filter(
          (tenant) => tenant.id !== tenent.id
        );
        setMyTenants(remainingTenant);
        //  await deletingTenantById(tenent)
        // await postTenantToDeleteOthers(tenent)
        trackEvent(
          `landlord ${getCurrentUser() && getCurrentUser().first_name
          } delete tenant`,
          tenants.first_name,
          "delete tenant Btn"
        );
        socket.emit("tenantToDeleteAndLeases", tenent, (errorDelete) => {
          if (errorDelete) {
            alert(t("This_tenant_was_already_Delete"));
          }
        });
      }
    } catch (ex) { }
  };

  //function to search a tenant
  const onChangeSearch = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTable(value, myTenants);
      setMyTenants(tableData);
      setFromSearch(true);
    } else {
      try {
        setLoadingSearchListings(true);
        const { data } = await getTenantById(getCurrentUser().id);
        setMyTenants(data);
        setLoadingSearchListings(false);
      } catch (error) {
        logger.log(
          "error from onChangeSearch function in ManageTenant component",
          error
        );
      }
    }
  };

  const searchTable = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data?.length; i++) {
      value = value.toLowerCase();
      let title = data[i].tenant_name.toLowerCase();

      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //sorting function of the tenants table
  const onChangeSort = (e) => {
    e.preventDefault();
    const isTenants = [...myTenants];
    const { value } = e.currentTarget;
    const column = { ...sortColumn };
    column.path = value;
    const sortedListing = _.orderBy(isTenants, [column.path], [column.order]);
    setMyTenants(sortedListing);
  };

  //function to download agreement of tenants that are members
  const downloadAgreement = async (tenant) => {
    try {
      setTenantToDownloadPDF(tenant.id);
      await postAndDownloadAgreementService(
        tenant.application_id,
        tenant.building_name,
        (data) => setTenantToDownloadPDF(data)
      );
    } catch (error) {
      logger.log(error);
    }
  };

  //function to download agreement of tenants that are non members
  // const downloadAgreementNonMember=async (urls)=>{
  //   logger.log("here is my URL=====", urls);
  //   <a >Download</a>
  // }

  // const downloadAgreementNonMember=async (urls)=>{
  // logger.log("here is my URL=====", urls);
  // await axios.get(`/messages/download/${URL}`, { responseType: 'blob' }).then((res)=>{
  //     const pdfBlob=new Blob([res.data], { type: 'application/pdf' })
  //     saveAs(pdfBlob, `${URL}`)
  //  }).catch((err)=>{
  //    logger.log("this is the error from downloadAgreementNonMember function in ManageTenants component",err);
  //  })
  // }

  //function that takes the side bar up
  const onTakeBarUp = () => {
    setUpSideBar(true);
  };

  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////
  //function to search Listing be the title, Type, Country and City

  const onChangeListingTitle = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTableListTitle(value, myListings);
      setMyListings(tableData);
    } else {
      setMyListings(myListings1);
    }
  };

  const searchTableListTitle = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data?.length; i++) {
      value = value.toLowerCase();
      let title = data[i].title.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  const onChangeListingType = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTableListType(value, myListings);
      setMyListings(tableData);
    } else {
      setMyListings(myListings1);
    }
  };

  const searchTableListType = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data?.length; i++) {
      value = value.toLowerCase();
      let title = data[i].listing_type.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  const onChangeListingCountry = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTableListCtry(value, myListings);
      setMyListings(tableData);
    } else {
      setMyListings(myListings1);
    }
  };

  const searchTableListCtry = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data?.length; i++) {
      value = value.toLowerCase();
      let title = data[i].country.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  const onChangeListingCity = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTableListCity(value, myListings);
      setMyListings(tableData);
    } else {
      setMyListings(myListings1);
    }
  };

  const searchTableListCity = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data?.length; i++) {
      value = value.toLowerCase();
      let title = data[i].city.toLowerCase();
      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //function to search tenant by name
  const onChangeTenantName = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTableTenantName(value, availableSpaces);
      setAvailableSpaces(tableData);
    } else {
      setAvailableSpaces(availableSpaces1);
    }
  };

  const searchTableTenantName = (value, data) => {
    return data.filter(d => d?.occupant_name?.toLowerCase().includes(value.toLowerCase()));
  };

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  const editUnregisteredTenant = (tenant) => {
    setFirstName(tenant.tenant_name.split(" ")[0]);
    setMiddleName(tenant.tenant_name.split(" ")[1]);
    setLastName(
      tenant.tenant_name.split(" ")[tenant.tenant_name.split(" ")?.length - 1]
    );
    setBuildingName(tenant.listing_id);
    setAptNumber(tenant.appartment_num);
    setPassportOrId(tenant.id_type);
    setIdCardNum(tenant.id_number);
    setJobTitle(tenant.job_title);
    setContactNumber(tenant.contact_number);
    setHouseAddress(tenant.house_address);
    setStartDate(tenant.agreement_start);
    setEndDate(tenant.agreement_end);
    setUploadIdCard(tenant.id_copy_img);
    setPhotoNameIdCard(tenant.photoNameIdCard);
    setUploadLeaseSign(tenant.sign_lease_img);
    setPhotoNameSignedLease(tenant.photoNameSignedLease);
    setEditing(true);
    setSelectedTenant(tenant);
    setSelectedListing(listings.find((l) => l.id == tenant.listing_id));
    setSelectedListingSpaces(
      availableSpaces.filter((s) => s.listing_id == tenant.listing_id)
    );
  };

  const onResetTenant = () => {
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setBuildingName("");
    setAptNumber("");
    setPassportOrId("");
    setIdCardNum("");
    setJobTitle("");
    setContactNumber("");
    setHouseAddress("");
    setStartDate("");
    setEndDate("");
    setUploadIdCard("");
    setPhotoNameIdCard("");
    setUploadLeaseSign("");
    setPhotoNameSignedLease("");
    setEditing(false);
    setSelectedTenant({});
    setSelectedListing({});
    setSelectedListingSpaces([]);
  };

  if (loading)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
      </div>
    );
  return (
    <>
      <Meta1
        title={`${getLang()}.${t("meta_tenant", {
          name: getCurrentUser().first_name,
        })}`}
        description="All My Tenants In Nawafrica"
      />

      {close && !upSideBar && (
        <Sidebar
          onClickCloseBar={onClickCloseBar}
          onTakeBarUp={onTakeBarUp}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs?.length
          }
        />
      )}

      {!close && (
        <OpenButton
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs?.length > 99 ? "99+" : unreadMsgs?.length
          }
        />
      )}

      {upSideBar && (
        <DownShowSideBar onUponUpSideBar={() => setUpSideBar(false)} />
      )}

      <div className="manageTenantOuterContainer">
        <ChatManagement
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs?.length
          }
        />

        <AddTenantModal
          onClickImage={(image) => {
            setUploadLeaseSign(uploadLeaseSign.filter((img) => img.public_id !== image.public_id))
          }}
          editing={editing}
          listings={myListings}
          onChangeFN={(e) => {
            e.preventDefault();
            const { value } = e.currentTarget;
            setFirstName(value);
          }}
          onChangeMN={(e) => {
            e.preventDefault();
            const { value } = e.currentTarget;
            setMiddleName(value);
          }}
          onChangeLN={(e) => {
            e.preventDefault();
            const { value } = e.currentTarget;
            setLastName(value);
          }}
          onChangeBN={(e) => {
            e.preventDefault();
            const { value } = e.currentTarget;
            setBuildingName(value);
            setSelectedListingSpaces(
              availableSpaces.filter((s) => s.listing_id == value)
            );
            setSelectedListing(listings.find((l) => l.id == value));
          }}
          onChangeAN={(e) => {
            e.preventDefault();
            const { value } = e.currentTarget;
            setAptNumber(value);
          }}
          onChangeIdType={(e) => {
            e.preventDefault();
            const { value } = e.currentTarget;
            setPassportOrId(value);
          }}
          onChangeIDT={(e) => {
            e.preventDefault();
            const { value } = e.currentTarget;
            setIdCardNum(value);
          }}
          onChangeJT={(e) => {
            e.preventDefault();
            const { value } = e.currentTarget;
            setJobTitle(value);
          }}
          onChangeUIC={onChangeUIC}
          onChangeCN={(e) => {
            e.preventDefault();
            const { value } = e.currentTarget;
            setContactNumber(`${value}`);
          }}
          onChangeHD={(e) => {
            e.preventDefault();
            const { value } = e.currentTarget;
            setHouseAddress(value);
          }}
          onChangeDate={(e) => {
            e.preventDefault();
            const { value } = e.currentTarget;
            setStartDate(value);
          }}
          onChangeUSL={onChangeUSL}
          onSubmitTenants={onSubmitTenants}
          photoNameIdCopy={photoNameIdCard}
          photoNameSignedLease={photoNameSignedLease}
          uploadLeaseSign={uploadLeaseSign}
          loadLeaseSign={loadLeaseSign}
          errorUploadLeaseSign={errorUploadLeaseSign}
          uploadIdCard={uploadIdCard}
          loadUploadIdCard={loadUploadIdCard}
          errorUploadIdCard={errorUploadIdCard}
          errorInInput={errorInInput}
          valueHD={houseAddress}
          valueFN={firstName}
          valueMN={middleName}
          valueLN={lastName}
          valueBN={buildingName}
          valueAN={aptNumber}
          valueIDN={idCardNum}
          valueJT={jobTitle}
          valueCN={contactNumber}
          postingTenant={postingTenant}
          valueStartDate={startDate}
          valueEndDate={endDate}
          onChangeDateEnd={(e) => {
            e.preventDefault();
            const { value } = e.currentTarget;
            setEndDate(value);
          }}
          availableSpaces={availableSpaces}
          selectedListingSpaces={selectedListingSpaces}
          selectedListing={selectedListing}
        />
      </div>

      <div className="manageTenantInnerCon">
        <div
          style={{ marginTop: "150px" }}
          className={
            close ? "applicationScreenTableCon" : "applicationScreenTableCon1"
          }
        >
          <TitleManageTenant
            tenants={
              myTenants?.length > 1000
                ? `${myTenants?.length / 1000}k`
                : myTenants?.length
            }
          />

          <SearchTenants
            onChangeSearch={onChangeSearch}
            onChangeSort={onChangeSort}
            onResetTenant={onResetTenant}
            myTenants={myTenants}
            myListings={myListings}
            availableSpaces={availableSpaces}
            users={users}
            onChangeListingTitle={onChangeListingTitle}
            onChangeListingType={onChangeListingType}
            onChangeListingCountry={onChangeListingCountry}
            onChangeListingCity={onChangeListingCity}
            onChangeTenantName={onChangeTenantName}
          />

          {myTenants && myTenants?.length > 0 ? (
            <TenantTable
              tenantToDownloadPDF={tenantToDownloadPDF}
              applicants={tenants}
              fromSearch={fromSearch}
              loadingSearchListings={loadingSearchListings}
              myTenants={myTenants}
              onDelete={onDelete}
              downloadAgreement={downloadAgreement}
              listings={listings}
              editUnregisteredTenant={editUnregisteredTenant}

            //  downloadAgreementNonMember={downloadAgreementNonMember}
            />
          ) : loadingSearchListings ? (
            <div>{t(".....loading")}</div>
          ) : (
            <div className="noListingAvailableYetCon">
              <div className="alert">
                {t("No_Tenant_Available_Yet")},{" "}
                <a
                  className="btn btn-light"
                  data-toggle="modal"
                  data-target="#exampleModalTenant"
                >
                  {t("Please_Post_A_Tenant")}
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <ScrollToTop smooth />
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </>
  );
};

export default ManageTenants;
