import React, { useState, useEffect, useContext } from "react";
import io from "socket.io-client";
import _, { get } from "lodash";
import logger from "../Services/loggerService";
import ScrollToTop from "react-scroll-to-top";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { trackEvent } from "../Utils/useGAEventTracker";

import Chats from "../components/AddListing/Chats";
import OpenButtonTenant from "../components/AddListing/OpenButtonTenant";
import SidebarTenant from "../components/AddListing/SideBarTenant";
import WelcomeUser from "../components/Leases/WelcomeUser";
import ProfileSpinner from "../components/profile/ProfileSpinner";
import PaymentOptions from "../components/Leases/PaymentOptions";
import EditMyTotalAmount from "../components/Leases/EditMyTotalAmount";
import SearchLease from "../components/Leases/SearchLease";
import TableLeases from "../components/Leases/TableLeases";
import { getCurrentUser } from "../Services/authService";
import { getListings } from "../Services/listings";
import {
  getALeaseSignById,
  getStatusByApplicantsId,
  postAndDownloadAgreementService,
  getApprovedStatusByTenantId,
} from "../Services/applicationStatus";
import AgreementLeasesList from "../components/Leases/AgreementLeasesList";
import AgreementLeaseModal from "../components/Leases/AgreementLeaseModal";
import { registeredUsers, getUserById } from "../Services/registerService";
import {
  updateStatusForAnApplicant,
  deleteAStatus,
} from "./../Services/applicationStatus";
import { deleteASignLease } from "../Services/applicationStatus";
import {
  tenantAddPaymentOption,
  getAllUsersPaymentMethods,
  DeleteAPaymentMethodById,
  tenantUpdatePaymentOption,
} from "../Services/paymentOptions";
import UnreadMsgsContext from "./../unreadmessages/context";
// import Meta from './../Utils/Meta'
import { callingCode } from "../Utils/callingCode";
import "../components/Leases/leases.css";
import LeasesOweNotice from "../components/Leases/LeasesOweNotice";
import ConfirmActivePayment from "../components/Leases/ConfirmActivePayment";
import { currencyExchange } from "../Utils/currencyExchange";
import { currencyCodes2 } from "../Utils/currencyCodes";
import { Regions } from "../Utils/regions";
import AgreementLeaseModal1 from "../components/Leases/AgreementLeaseModal1";
import Meta1 from "../Utils/Meta1";
import { countries } from "../Utils/countries1";
import { Chip } from "@mui/material";
import Swal from "sweetalert2";

const LeasesScreen = () => {
  const { t } = useTranslation();

  const [path, setPath] = useState("");
  const [close, setClose] = useState(true);

  const [myLeasesApplications, setMyLeasesApplications] = useState([]);
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);

  const [myUnderReviews, setMyUnderReviews] = useState([]);
  const [users, setUsers] = useState([]);
  const [sortColumn, setSortColumn] = useState({
    path: "dateposted",
    order: "desc",
  });

  const [socket, setSocket] = useState(null);

  //data for the listing and the users
  const [mySelectedListing, setMySelectedListing] = useState({});
  const [selectedApplicant, setSelectedApplicant] = useState({});
  const [selectedLandlord, setSelectedLandlord] = useState({});
  const [selectedLeaseToSign, setSelectedLeaseToSign] = useState({});
  const [send, setSend] = useState(false);
  const [loadingSignLease, setLoadingSignLease] = useState(false);

  const [leasePDFToDownload, setLeasePDFToDownload] = useState("");

  const [sortColumns, setSortColumns] = useState({
    path: "dateposted",
    order: "asc",
  });
  const [loadingSearchLease, setLoadingSearchLease] = useState(false);

  const [loadingMtnMomo, setLoadingMtnMomo] = useState(false);
  const [updatedPaymentMethod, setUpdatedPaymentMethod] = useState({});
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loadingVisaCard, setLoadingVisaCard] = useState(false);
  const [loadingPaypal, setLoadingPaypal] = useState(false);
  const [visaCardNumber, setVisaCardNumber] = useState("");
  const [visaCardDate, setVisaCardDate] = useState("");
  const [visaCardCvc, setVisaCardCvc] = useState("");
  const [paypal, setPaypal] = useState("");
  const [myPaymentMethodData, setMyPaymentMethodData] = useState([]);

  const [addPaymentError, setAddPaymentError] = useState("");

  const [underConstructionBankPayment, setUnderConstructionBankPayment] =
    useState(false);
  const [underConstructionPaypal, setUnderConstructionPaypal] = useState(false);

  const [selectedLeaseToEdit, setSelectedLeaseToEdit] = useState({});
  const [loadEdit, setLoadEdit] = useState(false);
  const [errorEdit, setErrorEdit] = useState("");

  const [clearDebt, setClearDebt] = useState(false);
  const [selectedPayment, setSelectPayment] = useState();
  const [loadUpdateActive, setLoadUpdateActive] = useState(false);
  const [activePaymentMethod, setActivePaymentMethod] = useState("");
  const [allLeaseOwed, setAllLeaseOwed] = useState([]);
  const [sender_currency, setSender_currency] = useState("");
  const [currencyEuroExchangeRate, setCurrencyEuroExchangeRate] = useState();
  const [error, setError] = useState("");
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [NoCloseSignModal, setNoCloseSignModal] = useState(false);

  const { unreadMsgs, myLeaseSigned, setMyLeaseSigned } =
    useContext(UnreadMsgsContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("portalPath", "/portal/tenant/leases");
    const myPath = localStorage.getItem("portalPath");
    setPath(myPath);
    getAllApplicants();
    return () => {
      localStorage.setItem("portalPath", "");
    };
  }, []);

  //function to get data for signed lease and under review application for a tenant
  const getAllApplicants = async () => {
    if (getCurrentUser()) {
      try {
        const sockets = io();
        setSocket(sockets);
        setLoading(true);
        const { data: myLeasesAppl } = await getStatusByApplicantsId(
          getCurrentUser().id
        );

        const sortedReviewLease = _.orderBy(
          myLeasesAppl,
          [sortColumn.path],
          [sortColumn.order]
        );

        const cancelled = sortedReviewLease.filter(
          (s) => s.tenant_close_deal && s.landlord_close_deal
        );
        const uncancelled = sortedReviewLease.filter(
          (s) => !s.tenant_close_deal && !s.landlord_close_deal
        );

        const landlordCancel = sortedReviewLease.filter(
          (s) => !s.tenant_close_deal && s.landlord_close_deal
        );

        const tenantCancel = sortedReviewLease.filter(
          (s) => s.tenant_close_deal && !s.landlord_close_deal
        );

        const leaseOwing = sortedReviewLease.filter(
          (p) => Number(p.amount_owed) > 0
        );
        setAllLeaseOwed(leaseOwing);
        setMyLeasesApplications([
          ...uncancelled,
          ...tenantCancel,
          ...landlordCancel,
          ...cancelled,
        ]);

        ////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////
        //involve with cancelling the agreement of a given lease
        sockets.on(
          `${getCurrentUser() && getCurrentUser().id
          }tenant_cancelled_agreement`,
          (data) => {
            let applStatus = data.applSign;
            let index = sortedReviewLease.findIndex(
              (t) => t.id == applStatus.id
            );
            let remainingStatus = sortedReviewLease.filter(
              (t) => t.id !== applStatus.id
            );
            remainingStatus.splice(index, 0, applStatus);
            setMyLeasesApplications(remainingStatus);
          }
        );

        const { data: listings } = await getListings();
        setListings(listings);

        /////////////////////////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////
        /////Getting Leases that are under review
        const { data: underReviewsData } = await getApprovedStatusByTenantId();

        const undDeletedReviews = underReviewsData.filter(
          (r) => r.deleted !== true
        );
        const sortedReview = _.orderBy(
          undDeletedReviews,
          [sortColumn.path],
          [sortColumn.order]
        );
        setMyUnderReviews(sortedReview);

        const { data: allUSers } = await registeredUsers();
        setUsers(allUSers);

        const { data: theAppl } = await getUserById(
          getCurrentUser() && getCurrentUser().id
        );

        setSelectedApplicant(theAppl);

        ///////////////////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////////////////
        ///collect data for user payment method
        const { data: myPaymentMethods } = await getAllUsersPaymentMethods();
        const column = { ...sortColumns };
        column.order = "desc";
        const sortedPayment = _.orderBy(
          myPaymentMethods,
          [column.path],
          [column.order]
        );

        setMyPaymentMethodData(sortedPayment);

        setLoading(false);
        ///////////////////////////////////////////////////////////////////
        //////////////////////////////////////////////////////////////////////
        ///collecting data to execute payment of debt
        const active = sortedPayment.filter((p) => p.active === true);

        if (active[0]) {
          const countryObj = currencyCodes2().filter(
            (x) => x.ctry === active[0].payment_credential.country
          );

          setSender_currency(countryObj[0].code);
          const currencyTo = "EUR";
          currencyExchange(
            countryObj[0].code,
            currencyTo,
            (exchange) => setCurrencyEuroExchangeRate(exchange),
            (err) => setError(err),
            (data) => logger.log("loading"),
            (data) => logger.log("loading")
          );
          setActivePaymentMethod(active[0]);
        } else if (sortedPayment.length > 0) {
          const countryObj = currencyCodes2().filter(
            (x) => x.ctry === sortedPayment[0].payment_credential.country
          );
          const currencyTo = "EUR";
          currencyExchange(
            countryObj[0].code,
            currencyTo,
            (exchange) => setCurrencyEuroExchangeRate(exchange),
            (err) => setError(err),
            (data) => logger.log("loading"),
            (data) => logger.log("loading")
          );
          setSender_currency(countryObj[0].code);
          setActivePaymentMethod(sortedPayment[0]);
        }

        ///////////////////////////////////////////////////////////////////
        ///////////////////////////////////////////////////////////////////
        ////////////////////////////////////////////////////////////////
        //websocket to sent and recieve data

        sockets.on(`${getCurrentUser().id}myReview`, (data) => {
          if (data) {
            const undeletedData = data.filter((d) => d.deleted !== true);
            const sortedReview = _.orderBy(
              undeletedData,
              [sortColumn.path],
              [sortColumn.order]
            );
            setMyUnderReviews(sortedReview);
          }
        });

        sockets.on(`${getCurrentUser().id}myReviewSign`, (data) => {
          if (data) {
            const sortedReview = _.orderBy(
              data,
              [sortColumn.path],
              [sortColumn.order]
            );
            setMyLeasesApplications(sortedReview);
            trackEvent(
              "land lord Signed review and sent to tenant",
              `websocket ${getCurrentUser().id}myReviewSign`,
              "websocket"
            );
          }
        });

        sockets.on(`${getCurrentUser().id}ConfirmEditPayment`, (data) => {
          const sortedReviewLease = _.orderBy(
            data,
            [sortColumn.path],
            [sortColumn.order]
          );
          setMyLeasesApplications(sortedReviewLease);
        });

        sockets.on(`${getCurrentUser().id}deleteApplication`, (data) => {
          const reviews = [...myUnderReviews];
          const undeletedReviews = reviews.filter((r) => r.id !== data.id);
          setMyUnderReviews(undeletedReviews);
        });
      } catch (error) {
        logger.log("error from LeaseScreen", error.message);
      }
    }
  };

  //function to close sidebar
  const onClickCloseBar = () => {
    setClose(false);
  };

  //function to open sidebar
  const onClickOpenBar = () => {
    setClose(true);
  };

  //function when in clicked on the lease under  review that need to be sign
  const selectedLeases = (lease) => {
    setSelectedLeaseToSign(lease);
    const myLandlord = users.filter(
      (user) => user.id === lease.list_owner_id
    )[0];
    setSelectedLandlord(myLandlord);
    const thisList = listings.filter((list) => list.id === lease.listing_id)[0];
    setMySelectedListing(thisList);
    setSend(false);
    trackEvent(
      "list all unsigned review for tenant",
      "all under reviews for a tenant"
    );
  };

  //function to convert date from dd-mm to dd/mm
  function dateConvert(date) {
    const dateArray = date.split("-");
    return dateArray ? `${dateArray[2]}/${dateArray[1]}` : "";
  }

  function yearsConverted(date) {
    const dateArray = date.split("-");
    return dateArray ? `${dateArray[0]}` : "";
  }

  //function to delete a lease that is still under review
  const onDeleteReview = async (review) => {
    try {
      const reviews = [...myUnderReviews];
      const deleteReviews = reviews.filter((un) => un.id !== review.id);
      setMyUnderReviews(deleteReviews);
      await deleteAStatus(review.id);
      socket.emit(`deleteReview`, review);
    } catch (error) {
      logger.log(
        "error in onDeleteReview function in LeasesScreen component",
        error
      );
    }
  };

  //function  for tenant to sign the lease
  const onSubmitAgreement = async (e) => {
    e.preventDefault();
    try {
      window.scrollTo(0, 0);
      setClose(true)
      Swal.fire({
        title: "Confirm lease sign?",
        // text: "You won't be able to revert this!",
        html: `<p>Are you sure you want to sign to ${mySelectedListing.rent_or_sell === "Rental" ? "rent" : "buy"} the property <span style='color:#ff6d60'>${mySelectedListing.title}</span> from <span style='color:#ff6d60'>${selectedLandlord.first_name} ${selectedLandlord.last_name}</span>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, sign lease!",
        cancelButtonText: "No, cancel lease!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoadingSignLease(true);
          const theReviews = [...myUnderReviews];
          const index = theReviews.indexOf(selectedLeaseToSign);
          const theReview = theReviews.splice(index, 1)[0];
          theReview.tenant_sign_lease = true;
          theReviews.splice(index, 0, theReview);
          setMyUnderReviews(theReviews);

          socket.emit("SignLeaseTenant", selectedLeaseToSign);
          await updateStatusForAnApplicant(selectedLeaseToSign.id);
          setLoadingSignLease(false);

          trackEvent(
            "tenant signing lease",
            selectedLeaseToSign.id,
            "tenant sign btn"
          );

          setSend(true);

          setNoCloseSignModal(true);

          setInterval(() => {
            setSend(false);
            setNoCloseSignModal(false);
          }, 3000);
          setSelectedLeaseToSign({});

          setMyLeaseSigned(
            myLeaseSigned.filter((l) => l.id !== selectedLeaseToSign.id)
          );

        }
      })


    } catch (error) {
      logger.log(error);
    }
  };

  //funtion to delete  signed lease
  const onDelete = async (lease) => {
    if (window.confirm(t("Are_you_sure_you_want_to_delete"))) {
      try {
        const leases = [...myLeasesApplications];
        const remainingLease = leases.filter((l) => l.id !== lease.id);
        setMyLeasesApplications(remainingLease);
        socket.emit(`deleteSignLease`, lease);
        await deleteASignLease(lease.id);
      } catch (error) {
        logger.log("onDelete funtion error", error);
      }
    }
  };

  //function to download the greement for a signed lease
  const downloadAgreement = async (lease) => {
    try {
      setLeasePDFToDownload(lease.id);
      const listing = listings.filter(
        (list) => list.id === lease.listing_id
      )[0];
      const applicationId = lease.application_id;

      trackEvent(
        "tenant download agreement PDF",
        `/createPDF/tenant/${applicationId}`,
        "tenant download btn"
      );
      await postAndDownloadAgreementService(
        applicationId,
        listing.title,
        (data) => setLeasePDFToDownload(data)
      );
    } catch (error) {
      logger.log(error);
    }
  };

  //function to sort a signed lease
  const onChangeSort = (e) => {
    e.preventDefault();
    const lease = [...myLeasesApplications];
    const { value } = e.currentTarget;
    const column = { ...sortColumns };
    column.path = value;
    const sortedLease = _.orderBy(lease, [column.path], [column.order]);
    setMyLeasesApplications(sortedLease);
  };

  //function to search signed lease
  const onChangeSearch = async (e) => {
    e.preventDefault();
    const { value } = e.currentTarget;
    if (value) {
      const tableData = searchTable(value, myLeasesApplications);
      setMyLeasesApplications(tableData);
      // setFromSearch(true)
    } else {
      try {
        setLoadingSearchLease(true);
        const { data: myLeasesAppl } = await getStatusByApplicantsId(
          getCurrentUser().id
        );
        const sortedReviewLease = _.orderBy(
          myLeasesAppl,
          [sortColumn.path],
          [sortColumn.order]
        );
        setMyLeasesApplications(sortedReviewLease);
        setLoadingSearchLease(false);
      } catch (error) {
        logger.log(
          "error in onChangeSearch function in LeasesScreen component"
        );
      }
    }
  };

  const searchTable = (value, data) => {
    let filteredArray = [];
    for (var i = 0; i < data.length; i++) {
      value = value.toLowerCase();
      let title = data[i].listing_name.toLowerCase();

      if (title.includes(value)) {
        filteredArray.push(data[i]);
      }
    }
    return filteredArray;
  };

  //function to add a payment option by the tenant
  const onhandleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedPaymentMethod === "MTN Mobile Money") {
        const Number = parseInt(phoneNumber);

        if (!countryCode) return setAddPaymentError(t("country_code_error"));
        if (!phoneNumber)
          return setAddPaymentError(t("MTN_Mobile_Number_is_not"));
        if (phoneNumber.length !== `${Number}`.length)
          return setAddPaymentError(
            t("All_the_Characters_in_the_phone_Number")
          );

        setLoadingMtnMomo(true);

        const ctry = countries().filter((code) => code.phone == countryCode)[0];

        const { data } = await tenantAddPaymentOption({
          type: selectedPaymentMethod,
          data: {
            momo_number: `${countryCode}${phoneNumber}`,
            country: ctry.label,
          },
        });

        trackEvent(
          "mobile money payment",
          `${countryCode}${phoneNumber}`,
          "add payment btn"
        );

        setUpdatedPaymentMethod({
          user_id: getCurrentUser().id,
          type: selectedPaymentMethod,
          momo_number: `${countryCode}${phoneNumber}`,
        });

        const column = { ...sortColumns };
        column.order = "desc";
        const sortedPayment = _.orderBy(data, [column.path], [column.order]);
        trackEvent(
          "mobile money payment",
          `${countryCode}${phoneNumber}`,
          "add payment btn"
        );

        setMyPaymentMethodData(sortedPayment);
        setLoadingMtnMomo(false);
        setPhoneNumber("");
        setCountryCode("");
        setAddPaymentError("");
      } else if (selectedPaymentMethod === t("Visa_Card")) {
        if (!visaCardNumber) return setAddPaymentError(t("Card_Number_is_not"));
        if (visaCardNumber.length < 16)
          return setAddPaymentError(t("Card_number_must_be"));
        if (!setVisaCardDate)
          return setAddPaymentError(t("Visa_Card_Expiry_date"));
        if (visaCardDate.length < 5)
          return setAddPaymentError("Card_Date_Must_be_5_digits");
        if (!visaCardCvc)
          return setAddPaymentError(t("CVC_is_not_allowed_to_be_empty"));
        if (visaCardCvc.length < 4)
          return setAddPaymentError(t("CVC_must_be_4_digit"));

        setLoadingVisaCard(true);
        const { data } = await tenantAddPaymentOption({
          type: selectedPaymentMethod,
          data: {
            card_number: visaCardNumber,
            card_date: visaCardDate,
            card_cvc: visaCardCvc,
          },
        });

        setUpdatedPaymentMethod({
          user_id: getCurrentUser().id,
          type: selectedPaymentMethod,
          visa_card_number: visaCardNumber,
        });
        const column = { ...sortColumns };
        column.order = "desc";
        const sortedPayment = _.orderBy(data, [column.path], [column.order]);
        trackEvent("visa card payment", `${visaCardNumber}`, "add payment btn");

        setMyPaymentMethodData(sortedPayment);
        setLoadingVisaCard(false);
        setVisaCardNumber("");
        setVisaCardDate("");
        setVisaCardCvc("");
        setAddPaymentError("");
      } else {
        if (!paypal) return setAddPaymentError(t("Paypal_Account_is_not"));
        setLoadingPaypal(true);
        const { data } = await tenantAddPaymentOption({
          type: selectedPaymentMethod,
          data: {
            paypal_account: paypal,
          },
        });

        setUpdatedPaymentMethod({
          user_id: getCurrentUser().id,
          type: selectedPaymentMethod,
          paypal_account: paypal,
        });

        const column = { ...sortColumns };
        column.order = "desc";
        const sortedPayment = _.orderBy(data, [column.path], [column.order]);
        trackEvent("Paypal payment", `${paypal}`, "add payment btn");

        setMyPaymentMethodData(sortedPayment);

        setLoadingPaypal(false);
        setPaypal("");
        setAddPaymentError("");
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setAddPaymentError(ex.response.data);
        setLoadingMtnMomo(false);
        setLoadingVisaCard(false);
        setLoadingPaypal(false);
      }
    }
  };

  const valueCardNumber = () => {
    if (visaCardNumber.length >= 16) {
      return `${visaCardNumber.slice(0, 4)} ${visaCardNumber.slice(
        4,
        8
      )} ${visaCardNumber.slice(8, 12)} ${visaCardNumber.slice(12, 16)}`;
    } else {
      return visaCardNumber;
    }
  };

  const valueCardDate = () => {
    if (visaCardDate.length === 2) {
      return `${visaCardDate}/`;
    } else {
      return visaCardDate;
    }
  };

  //function to Delete a payment method
  const onDeletePaymentMethod = async (method) => {
    if (
      window.confirm(t("Are_you_sure_you_want_to_delete_the_payment_method?"))
    ) {
      const myRemainMethod = myPaymentMethodData.filter(
        (meth) => meth !== method
      );
      setMyPaymentMethodData(myRemainMethod);

      await DeleteAPaymentMethodById(method.id);
    }
  };

  //function that allow the tenant to confirm changes made by landlord
  const confirmEdit = (lease) => {
    setLoadEdit(true);
    const theLease = { ...lease };
    theLease.action = "confirm";
    socket.emit("confirmOrCancelLease", theLease, ({ error }) => {
      if (error) {
        alert(error);
        setErrorEdit(error);
        setLoadEdit(false);
      } else {
        setLoadEdit(false);
        setSelectedLeaseToEdit({});
      }
    });
  };

  //function that allow the tenant to cancel changes made by landlord
  const cancelEdit = (lease) => {
    setLoadEdit(true);
    const theLease = { ...lease };
    theLease.action = "cancel";
    socket.emit("confirmOrCancelLease", theLease, ({ error }) => {
      if (error) {
        alert(error);
        setErrorEdit(error);
        setLoadEdit(false);
      } else {
        setLoadEdit(false);
        setSelectedLeaseToEdit({});
      }
    });
  };

  ////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////
  //function responsible for clearing debt of a signed lease
  const onClickYes = async () => {
    try {
      setLoadUpdateActive(true);
      const presentActive = myPaymentMethodData.filter(
        (p) => p.active === true
      )[0];

      let remainingPaymentMethod = [...myPaymentMethodData];
      if (presentActive) {
        remainingPaymentMethod = myPaymentMethodData.filter(
          (p) => p.active !== true
        );
        presentActive.active = false;
        remainingPaymentMethod.push(presentActive);
      }
      selectedPayment.active = true;
      remainingPaymentMethod = remainingPaymentMethod.filter(
        (p) => p.id !== selectedPayment.id
      );
      remainingPaymentMethod.push(selectedPayment);
      const column = { ...sortColumns };
      column.order = "desc";
      const sortedPayment = _.orderBy(
        remainingPaymentMethod,
        [column.path],
        [column.order]
      );
      setMyPaymentMethodData(sortedPayment);
      setActivePaymentMethod(selectedPayment);

      await tenantUpdatePaymentOption(selectedPayment);

      setSelectPayment(null);
      setLoadUpdateActive(false);
    } catch (ex) { }
  };

  const onClickPayDebt = async (data) => {
    setLoadingPayment(data.id);
    const theListing = listings.filter((l) => l.id === data.listing_id)[0];
    const theCountry = Regions().filter(
      (ctry) => ctry.country === theListing.country
    )[0];

    const lease = await getALeaseSignById(data.application_id);

    const listOwner = await getUserById(data.list_owner_id);

    var options = {
      method: "GET",
      url: "https://currency-exchange.p.rapidapi.com/exchange",
      params: {
        from: sender_currency,
        to: theCountry.currencyCode,
        q: "1.0",
      },
      headers: {
        "x-rapidapi-host": "currency-exchange.p.rapidapi.com",
        "x-rapidapi-key": "05279b6b9bmshc8e3d21a4c373ccp13108bjsnbece35678c93",
      },
    };

    axios
      .request(options)
      .then(function (response) {
        const totalAmt = currencyEuroExchangeRate * Number(data.amount_owed);
        const paymentData = {
          amount: totalAmt,
          payment_method: activePaymentMethod,
          landlord: listOwner.data,
          sender: getCurrentUser(),
          listing: theListing,
          lease_signed: data,
          lease_signed_details: lease.data,
          listCurrencyCode: theCountry.currencyCode,
          amt_no_eur: Number(data.amount_owed) * response.data,
          sender_currency,
          currentEuroExchangeRate: currencyEuroExchangeRate,
          application_id: lease.data.application_id,
          listingExchange: response.data,
          amount_on_list_exchamge: Number(data.amount_owed),
          pay_debt: true,
        };

        // // const { data } = await payNow(paymentData)
        // socket.emit(
        //   'tenantPaybills',
        //   paymentData,
        //   ({ error, leaseSigned, paymentDetails, paidData }) => {
        //     setError('')
        //     if (error) {
        //       setLoadingPayment(false)
        //       setError(error)
        //     } else {
        //       const sortedPayment = _.orderBy(
        //         paidData,
        //         [sortColumn.path],
        //         [sortColumn.order]
        //       )

        //       // setPayments(sortedPayment)
        //       // setMySignLease(leaseSigned)
        //       // setPaymentDetails(paymentDetails)
        //       const myVideoToPlay = document.getElementById('myVideoPay')
        //       myVideoToPlay && myVideoToPlay.play()
        //       setLoadingPayment(false)
        //     }
        //   }
        // )

        // logger.log('HERE IS THE DATA FOR PAYMENT======', paymentData)
      })
      .catch(function (error) {
        const totalAmt = currencyEuroExchangeRate * Number(data.amount_owed);
        const paymentData = {
          amount: totalAmt,
          payment_method: activePaymentMethod,
          landlord: listOwner.data,
          sender: getCurrentUser(),
          listing: theListing,
          lease_signed: lease.data,
          lease_signed_details: data,
          listCurrencyCode: theCountry.currencyCode,
          sender_currency,
          amt_no_eur: Number(data.amount_owed),
          currentEuroExchangeRate: currencyEuroExchangeRate,
          application_id: lease.data.application_id,
          listingExchange: 1,
          amount_on_list_exchamge: Number(data.amount_owed),
        };
      });

    // const currencyTo = 'EUR'
    // countryObj &&
    //   currencyExchange(
    //     countryObj[0].code,
    //     currencyTo,
    //     (exchange) => {
    //       setCurrencyEuroExchangeRate(exchange)
    //     },
    //     (err) => setError('Currency exchange error, please retry')
    //   )

    // socket.emit(
    //   'tenantPaybills',
    //   paymentData,
    //   ({ error, leaseSigned, paymentDetails, paidData }) => {
    //     setError('')
    //     if (error) {
    //       setLoadingPayment(false)
    //       setError(error)
    //     } else {detailLeaseSign,
    //   listCurrencyCode,
    //   sender_currency,
    //   amt_no_eur: Number(amount
    //       const sortedPayment = _.orderBy(
    //         paidData,
    //         [sortColumn.path],
    //         [sortColumn.order]
    //       )
    //       setPayments(sortedPayment)
    //       setMySignLease(leaseSigned)
    //       setPaymentDetails(paymentDetails)
    //       const myVideoToPlay = document.getElementById('myVideoPay')
    //       myVideoToPlay && myVideoToPlay.play()
    //       setLoadingPayment(false)
    //       setPaid(true)
    //       setAmount('')
    //       setSelectedPayment('')
    //       setTimeout(() => {
    //         setPaid(false)
    //       }, 5000)
    //     }
    //   }
    // )
  };

  function capitalize(s) {
    return s.toLowerCase().replace(/\b./g, function (a) {
      return a.toUpperCase();
    });
  }

  if (loading)
    return (
      <div className="homeSpinnerContainer">
        <ProfileSpinner />
        {/* <LottieLoader /> */}
      </div>
    );
  return (
    <>
      {clearDebt && (
        <LeasesOweNotice
          setClearDebt={(data) => setClearDebt(data)}
          onClickCancel={() => setClearDebt(false)}
          allLeaseOwed={allLeaseOwed}
          // allLeaseOwed={myLeasesApplications}
          onClickPayDebt={onClickPayDebt}
          loadingPayment={loadingPayment}
        />
      )}
      <Meta1
        title={t("Nawafrica_name_signed_leases", {
          name: getCurrentUser() && getCurrentUser().first_name,
        })}
        description={`All My Leases Signed In Nawafrica`}
        keywords="All Lease Signed"
        link="/portal/tenant/leases"
      />

      {close && (
        <SidebarTenant
          onClickCloseBar={onClickCloseBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      {!close && (
        <OpenButtonTenant
          onClickOpenBar={onClickOpenBar}
          path={path}
          chats={
            unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
          }
        />
      )}

      <EditMyTotalAmount
        selectedLeaseToEdit={selectedLeaseToEdit}
        listings={listings}
        confirmEdit={confirmEdit}
        cancelEdit={cancelEdit}
        errorEdit={errorEdit}
      />

      {selectedLeaseToSign.id && (
        <AgreementLeaseModal1
          onClickCancel={() => setSelectedLeaseToSign({})}
          leases={"The Particular leases"}
          onclickDone={() => setNoCloseSignModal(false)}
          NoCloseSignModal={NoCloseSignModal}
          valueDateAgreedMonth={dateConvert(
            selectedLeaseToSign.create_date
              ? selectedLeaseToSign.create_date.slice(0, 10)
              : ""
          )}
          valueDateAgreedYear={yearsConverted(
            selectedLeaseToSign.create_date
              ? selectedLeaseToSign.create_date.slice(0, 10)
              : ""
          )}
          selectedLandlord={selectedLandlord}
          valueLandlordName={`${selectedLandlord.first_name} ${selectedLandlord.middle_name} ${selectedLandlord.last_name}`}
          valueEmail={selectedLandlord.email}
          valueLandlordCity={selectedLandlord.city}
          valueLandlordState={selectedLandlord.states}
          valueLandlordCountry={selectedLandlord.country}
          valueTenantName={`${selectedApplicant.first_name} ${selectedApplicant.middle_name} ${selectedApplicant.last_name}`}
          valueProfession={selectedApplicant.profession}
          valueListCountry={mySelectedListing.country}
          valueListState={mySelectedListing.state}
          valueListCity={mySelectedListing.city}
          valueListAddress={
            mySelectedListing.street_address &&
              mySelectedListing.street_address.length > 30
              ? mySelectedListing.street_address.slice(0, 30) + ".."
              : mySelectedListing.street_address
          }
          valueListType={mySelectedListing.listing_type}
          // onChangeAppartNum={"onChange when the landlord will field The House Number"}
          valueBedrooms={mySelectedListing.bedrooms}
          valueBathroom={mySelectedListing.bathrooms}
          valueKitchen={mySelectedListing.kitchen}
          citeName={mySelectedListing.title}
          submitAgreement={onSubmitAgreement}
          loadingSignLease={loadingSignLease}
          send={send}
          fixedAgreement={mySelectedListing.fixed_agreement}
          renewFixedAgreement={
            mySelectedListing.agreement_after_fixed_agreement
              ? mySelectedListing.agreement_after_fixed_agreement
                .renew_fixed_agreement
              : ""
          }
          continueWithMonthToMonth={
            mySelectedListing.agreement_after_fixed_agreement
              ? mySelectedListing.agreement_after_fixed_agreement
                .continue_with_month_to_month
              : ""
          }
          vacatePremise={
            mySelectedListing.agreement_after_fixed_agreement
              ? mySelectedListing.agreement_after_fixed_agreement.vacateP
              : ""
          }
          monthToMonthAgreement={mySelectedListing.month_to_month_agreement}
          soldAgreement={mySelectedListing.sold_agreement}
        />
      )}
      <AgreementLeaseModal
        leases={"The Particular leases"}
        onclickDone={() => setNoCloseSignModal(false)}
        NoCloseSignModal={NoCloseSignModal}
        valueDateAgreedMonth={dateConvert(
          selectedLeaseToSign.create_date
            ? selectedLeaseToSign.create_date.slice(0, 10)
            : ""
        )}
        valueDateAgreedYear={yearsConverted(
          selectedLeaseToSign.create_date
            ? selectedLeaseToSign.create_date.slice(0, 10)
            : ""
        )}
        valueLandlordName={`${selectedLandlord.first_name} ${selectedLandlord.middle_name} ${selectedLandlord.last_name}`}
        valueEmail={selectedLandlord.email}
        valueLandlordCity={selectedLandlord.city}
        valueLandlordState={selectedLandlord.states}
        valueLandlordCountry={selectedLandlord.country}
        valueTenantName={`${selectedApplicant.first_name} ${selectedApplicant.middle_name} ${selectedApplicant.last_name}`}
        valueProfession={selectedApplicant.profession}
        valueListCountry={mySelectedListing.country}
        valueListState={mySelectedListing.state}
        valueListCity={mySelectedListing.city}
        valueListAddress={
          mySelectedListing.street_address &&
            mySelectedListing.street_address.length > 30
            ? mySelectedListing.street_address.slice(0, 30) + ".."
            : mySelectedListing.street_address
        }
        valueListType={mySelectedListing.listing_type}
        // onChangeAppartNum={"onChange when the landlord will field The House Number"}
        valueBedrooms={mySelectedListing.bedrooms}
        valueBathroom={mySelectedListing.bathrooms}
        valueKitchen={mySelectedListing.kitchen}
        citeName={mySelectedListing.title}
        submitAgreement={onSubmitAgreement}
        loadingSignLease={loadingSignLease}
        send={send}
        fixedAgreement={mySelectedListing.fixed_agreement}
        renewFixedAgreement={
          mySelectedListing.agreement_after_fixed_agreement
            ? mySelectedListing.agreement_after_fixed_agreement
              .renew_fixed_agreement
            : ""
        }
        continueWithMonthToMonth={
          mySelectedListing.agreement_after_fixed_agreement
            ? mySelectedListing.agreement_after_fixed_agreement
              .continue_with_month_to_month
            : ""
        }
        vacatePremise={
          mySelectedListing.agreement_after_fixed_agreement
            ? mySelectedListing.agreement_after_fixed_agreement.vacateP
            : ""
        }
        monthToMonthAgreement={mySelectedListing.month_to_month_agreement}
        soldAgreement={mySelectedListing.sold_agreement}
      />

      <div className="leasingListAndTableOuterContainer">
        <div
          className={
            close
              ? "leasingTableContainerForLeasing"
              : "leasingTableContainerForLeasing1"
          }
        >
          {selectedPayment && (
            <ConfirmActivePayment
              selectedPayment={selectedPayment}
              onClickCancel={() => setSelectPayment(null)}
              onClickYes={onClickYes}
              onClickNo={() => setSelectPayment(null)}
              loadUpdateActive={loadUpdateActive}
            />
          )}

          <Chats
            chats={
              unreadMsgs && unreadMsgs.length > 99 ? "99+" : unreadMsgs.length
            }
          />

          <div className="leaseNameContainer">
            <WelcomeUser
              user={capitalize(
                `${getCurrentUser() &&
                getCurrentUser().first_name &&
                getCurrentUser().first_name
                }`
              )}
            />
          </div>

          <SearchLease
            onChangeSearch={onChangeSearch}
            onChangeSort={onChangeSort}
          />

          <div className="leaseTableTitleContainer">
            <h6>
              {t("Your_Leases")}{" "}
              <Chip
                label={`${myLeasesApplications.length > 99
                  ? "99+"
                  : myLeasesApplications.length
                  }`}
              />
            </h6>
          </div>
          {myLeasesApplications.length > 0 ? (
            <TableLeases
              onDelete={onDelete}
              leasePDFToDownload={leasePDFToDownload}
              downloadAgreement={downloadAgreement}
              myLeasesApplications={myLeasesApplications}
              listings={listings}
              loadingSearchLease={loadingSearchLease}
              onSelectEdit={(lease) => setSelectedLeaseToEdit(lease)}
              loadEdit={loadEdit}
              selectedLeaseToEdit={selectedLeaseToEdit}
            />
          ) : loadingSearchLease ? (
            <div>{t(".....loading")}</div>
          ) : (
            <div className="noLeasesContainer">
              <div className="alert">{t("No_Leases_Available_Yet")}</div>
            </div>
          )}
        </div>

        <div
          className={close ? "LeasingListContainer" : "LeasingListContainer1"}
        >
          <div className="theContainerThatRapTheListItem">
            {myUnderReviews.length > 0 ? (
              <AgreementLeasesList
                myUnderReviews={myUnderReviews}
                selectedLeaseToSign={selectedLeaseToSign}
                listings={listings}
                users={users}
                onClick={selectedLeases}
                onDeleteReview={onDeleteReview}
              />
            ) : (
              <div className="noSignedLeaseAvailableContainer">
                <div className="alert">{t("No_Lease_Available_To_Sign")}</div>
              </div>
            )}
          </div>

          <PaymentOptions
            onClickSelectedPayment={(data) => setSelectPayment(data)}
            myPaymentMethodData={myPaymentMethodData}
            loadingMtnMomo={loadingMtnMomo}
            updatedPaymentMethod={updatedPaymentMethod}
            onClickPaymentMethod={(method) => setSelectedPaymentMethod(method)}
            selectedPaymentMethod={selectedPaymentMethod}
            onChangeCountryCode={(e, values) => {
              if (values) {
                setCountryCode(values.phone);
              } else {
                setCountryCode("");
              }
            }}
            onChangePhoneNumber={(e) => setPhoneNumber(e.target.value)}
            onhandleSubmit={onhandleSubmit}
            onChangeVisaCardNumber={(e) => setVisaCardNumber(e.target.value)}
            loadingVisaCard={loadingVisaCard}
            onChangePaypal={(e) => {
              let value = e.target.value;
              if (value) {
                setPaypal(value);
              } else {
                setPaypal("");
              }
            }}
            loadingPaypal={loadingPaypal}
            valueCardNumber={valueCardNumber()}
            valueCardDate={valueCardDate()}
            onChangeVisaCardDate={(e) => setVisaCardDate(e.target.value)}
            onChangeCvc={(e) => setVisaCardCvc(e.target.value)}
            valueCardCvc={visaCardCvc}
            valueCountryCode={countryCode}
            valuePhoneNumber={phoneNumber}
            valuePaypal={paypal}
            addPaymentError={addPaymentError}
            onDeletePaymentMethod={onDeletePaymentMethod}
            underConstructionBankPayment={underConstructionBankPayment}
            underConstructionPaypal={underConstructionPaypal}
          />
        </div>
      </div>
      <ScrollToTop smooth />
      <div className="letMeGiveSomeSeparationOverAccountable"></div>
    </>
  );
};

export default LeasesScreen;
